/* color: #172e08; */
/* background-color: #d8ffc0; */

#buyer {
  width: 100%;
  height: max-content;
  /* border: thin solid #172e08; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#buyer-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: max-content;
}
#buyer-title {
  width: 100%;
  height: 7vw;
  display: flex;
  font-size: 2vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#buyer-heading {
  font-weight: bold;
  font-size: 2.3vw;
}

#buyer-par {
  color: grey;
  font-size: 1.6vw;
}

#compost-title {
  width: 100%;
  height: 5vw;
  display: flex;
  font-size: 3vw;
  /* border: thin solid #172e08; */
  font-weight: bold;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#compost-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.compost-card {
  height: 30vw;
  /* margin: 1vw; */
  /* border: thin solid #172e08; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin-left: 1.3vw;
  margin-right: 1vw;
  margin-top: 1vw;
  margin-bottom: 1vw;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.compost-card > * {
  width: 95%;
  /* border: thin solid #172e08; */
}

.compost-card-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 45%;
}

.compost-card-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 10%;
}

.compost-card-image > img {
  width: 100%;
  height: 100%;
}

.compost-card-info > .infos {
  width: 30%;
  font-size: 1.3vw;
  color: #172e08;
  height: 95%;
  background-color: white;
  border: thin solid white;
}
.compost-card-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5vw;
  height: 30%;
}
.compost-card-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 12%;
}

.compost-card-buttons > button {
  width: 45%;
  height: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #172e08;
  font-size: 1.5vw;
  background-color: transparent;
  border: thin solid transparent;
}

.compost-card-buttons > .order {
  background-color: #172e08;
  color: #d8ffc0;
  font-size: 1.7vw;
}
