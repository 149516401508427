@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

* {
  font-family: "Mulish", sans-serif;
}

body {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #d8ffc0;
  color: #172e08;
}

button {
  cursor: pointer;
}

#root {
  width: 90%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

main {
  min-height: 46vw;
  width: 100%;
}

footer {
  width: 100%;
  height: 20vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

header {
  width: 100%;
  height: 5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

header > * {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

header a {
  text-decoration: none;
  color: #172e08;
}

#header-logo {
  height: 100%;
  width: 25%;
  font-size: 3vw;
  font-weight: bold;
}

#header-info {
  font-size: 1.5vw;
  height: 70%;
  width: 45%;
}

#header-forms {
  font-size: 2vw;
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

#header-forms > a {
  width: 45%;
  background-color: #172e08;
  height: 70%;
  color: #d8ffc0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 0.2vw;
}

#header-forms :hover {
  color: #172e08;
  border: double #172e08 0.5vw;
  background-color: #d8ffc0;
}
