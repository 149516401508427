#redirect {
  width: 100%;
  height: 45vw;
  display: flex;
  background-color: #d8ffc0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#redirect-title {
  width: 100%;
  height: 20%;
  font-size: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#redirect-head {
  font-size: 3vw;
  font-weight: bold;
}

#redirect-par {
  color: grey;
}

#redirect-cont {
  width: 100%;
  height: 30%;
  font-size: 2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.redirects {
  width: 20%;
  height: 90%;
  border-radius: 0.5vw;
  text-decoration: none;
  color: #172e08;
  font-size: 1.7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}

.redirects > img {
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.redirects > span {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.redirects:hover {
  background-color: #d8ffc0;
  height: 100%;
  box-shadow: 0.02vw 0.02vw, 0.5vw grey;
}
