#collector {
    background-color: #ECF0F8;
    color: #172E08;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#collector>* {
    width: 100%;
    height: 10%;
}

#collector-bar {
    width: 100%;
    height: 4vw;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#collector-bar>* {
    font-size: 2vw;
    text-decoration: none;
    color: #172E08;
}

#collector-bar>* {
    margin-left: 2vw;

}

/* colletcor-home ========================================================= */

#collector-home {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}

#collector-stats {
    width: 100%;
    height: 25vw;
    border: thin solid #172E08;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

#collector-stats-terms {
    border: thin solid #172E08;
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#collector-stats-graph {
    border: thin solid #172E08;
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#collector-stats-figures {
    border: thin solid #172E08;
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}


/* colector collections ======================================== */

#collector-collections {
    height: max-content;
    width: 100%;
    border: thin solid #172E08;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#collector-collection-header {
    width: 100%;
    height: 4vw;
    font-size: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    background-color: #ECF0F8;
    border: thin solid #172E08;

}

#collector-container {
    width: 100%;
    height: 60vw;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

#collector-container-list {
    width: 65%;
    height: 100%;
    border: thin solid #172E08;
    display: grid;
    grid-template-columns: repeat(3, 18vw);
    grid-template-rows: repeat(auto, 20vw);
}

#collector-container-view {
    width: 35%;
    height: 100%;
    border: thin solid #172E08;
}

/* waste card ================================================================== */

.waste-card {
    /* width: 15vw;
    height: 20vw; */
    margin-left: 2.5vw;
    margin-top: 2vw;
    border: thin solid #172E08;
}

/* collector-profile ========================================================= */

#collector-profile {
    height: 60vw;
    width: 100%;
}
