#poser-profile {
  height: max-content;
  width: 100%;
  background-color: #d8ffc0;
  color: #172e08;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#profile-title {
  width: 100%;
  height: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#profile-head {
  font-size: 2.5vw;
  font-weight: bold;
}

#profile-par {
  font-size: 1.7vw;
  color: grey;
}

#profile-form {
  background-color: white;
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 90%;
}

input {
  text-align: center;
}

.form-cont {
  margin-bottom: 0.5vw;
  height: 7vw;
  width: 100%;
  border: thin solid #172e08;
  font-size: 1.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

#form-buttons {
  width: 100%;
  height: 4vw;
  border: thin solid #172e08;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

#form-buttons > * {
  border: thin solid #172e08;
  height: 100%;
  font-size: 1.5vw;
  width: 15%;
  background-color: #172e08;
  color: #d8ffc0;
}

select {
  width: 90%;
  height: 40%;
  font-size: 1.5vw;
  background-color: #d8ffc0;
  color: #172e08;
  border: thin solid #172e08;
}

select:focus {
  outline: none;
}

#poser-profile input {
  width: 90%;
  height: 40%;
  font-size: 1.5vw;
  color: #172e08;
  border: thin solid #172e08;
}

#locations > * {
  width: 25%;
}
