#disposer-nav {
  width: 100%;
  height: 4vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

#disposer-nav > * {
  margin-left: 1vw;
  margin-right: 1vw;
  text-decoration: none;
  width: 10%;
  height: 90%;
  background-color: #172e08;
  color: #172e08;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 0.3vw solid #172e08;
  font-size: 1.8vw;
  border-radius: 0.2vw;
}

#disposer-nav > *:hover {
  background-color: #d8ffc0;
  color: #172e08;
}

/* disposer home ======================================================== */

#disposer-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.5vw;
  font-weight: bold;
  background-color: white;
  width: 100%;
  background-color: #d8ffc0;
}

#disposer-home > * {
  margin-top: 0.5vw;
  /* border: thin solid #172e08; */
}

#disposer-home-title {
  width: 100%;
  height: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3vw;
  background-color: white;
  font-weight: bold;
}

/* disposer stats ======================================================== */
#user-form {
  width: 100%;
  height: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #d8ffc0;
  justify-content: center;
}

#user-form form {
  width: 80%;
  height: 90%;
  background-color: #172e08;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#user-form-title {
  width: 100%;
  height: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#user-form-cont {
  width: 100%;
  height: 88%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#user-form-icons {
  width: 10%;
  height: 90%;
  margin-top: -2vw;
  font-size: 1.3vw;
  /* border: double .3vw #d8ffc0; */
  border-bottom-right-radius: 2vw;
  border-top-right-radius: 2vw;
}

#user-form-inputs {
  font-size: 1.3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: 100%;
}

#user-form-inputs > span {
  font-size: 1.3vw;
  width: 95%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#user-form-inputs > span > input {
  width: 50%;
  font-size: 1.1vw;
  color: #172e08;
  border: thin solid #172e08;
  text-align: center;
  font-weight: lighter;
  height: 60%;
  border-radius: 0.5vw;
}

#user-form-inputs > span > input:focus {
  outline: thin solid #172e08;
}

#user-form-inputs > span > label {
  font-size: 1.3vw;
  width: 60%;
}

#user-form-inputs > button {
  font-size: 1.7vw;
  width: 30%;
  height: 10%;
  border: thin solid #172e08;
  background-color: white;
  color: #172e08;
  margin-bottom: 0.5vw;
}

#user-form-image {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

#user-form-image > span {
  width: 100%;
  height: 90%;
  background-color: #d8ffc0;
  border-top-left-radius: 30vw;
  border-top-right-radius: 30vw;
  border-bottom-left-radius: 30vw;
  background-image: url("../images/Africa.jpg");
  background-position: center;
  background-size: contain;
}

/* disposer stats ======================================================== */

#disposer-stats {
  width: 100%;
  height: 30vw;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

#disposer-stats-title {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#disposer-stats-stats {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#disposer-stats-stats > #word {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#disposer-stats-stats > #graph {
  height: 85%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#disposer-stats-words {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#disposer-stats-title > *,
#disposer-stats-words > * {
  width: 90%;
  height: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.8vw;
  background-color: #172e08;
  color: #ffffff;
  border-radius: 0.7vw;
}

/* disposer previous =========================================================== */

#disposer-previous {
  width: 100%;
  margin-top: -0.5vw;
  height: 45vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

#disposer-list {
  width: 65%;
  background-color: white;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

#disposer-item {
  margin-left: 1vw;
  margin-right: 1vw;
  background-color: #d8ffc0;
  margin-top: 1vw;
  color: #172e08;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 20vw;
}

#disposer-item > * {
  width: 100%;
  border-radius: inherit;
}

.item-image {
  height: 48%;
}
.item-image > img {
  width: 100%;
  height: 100%;
}

.item-info {
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.item-info > button {
  border: thin solid transparent;
  background-color: transparent;
  color: #172e08;
  font-size: .9vw;
  text-align: center;
  height: 90%;
  width: 50%;
}
.item-insts {
  height: 30%;
  font-size: 1vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.item-buts {
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.item-buts > * {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: thin solid #172e08;
  background-color: transparent;
  color: #172e08;
  font-size: 1vw;
  height: 90%;
  width: 47%;
}
/* disposer view ============================================ */
#disposer-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 35%;
  background-color: white;
  height: 100%;
  background-color: #d8ffc0;
}

#disposer-view > * {
  width: 95%;
}

#view-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.7vw;
  height: 10%;
}

#view-image {
  height: 35%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#view-image > img {
  height: 100%;
}
#view-info {
  height: 10%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1vw;
}

.view-attr {
  font-size: 1.2vw;
  border: thin solid #172e08;
  height: 3.5vw;
  color: #d8ffc0;
  background-color: #172e08;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

#view-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  font-size: 1.5vw;
  height: 9%;
}

#view-value > * {
  width: 45%;
  border: thin solid #172e08;
  background-color: #172e08;
  height: 90%;
  font-size: 1.5vw;
  text-align: left;
  display: flex;
  color: #d8ffc0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: thin solid;
}

#view-instructions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5vw;
  height: 25%;
}

#view-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  font-size: 1.7vw;
  height: 10%;
}

#view-buttons > * {
  width: 45%;
  border: thin solid #172e08;
  height: 90%;
  font-size: 1.7vw;
  color: #172e08;
  background: #d8ffc0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#view-delete {
  background-color: #172e08;
  color: #d8ffc0;
}

#view-delete:hover {
  background-color: red;
  font-size: 2vw;
  color: white;
  border: thin solid red;
}

#view-update:hover {
  background-color: rgb(0, 56, 121);
  font-size: 2vw;
  color: white;
  border: thin solid rgb(0, 56, 121);
}
