.auth {
  width: 100%;
  height: 44vw;
  border: thin solid;
  border: thin solid #d8ffc0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#login {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#form-div {
  width: 40%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-image: url("../images/paradise2.jpg");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-title {
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-title > * {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50%;
}

.login-title > #title {
  font-size: 3vw;
  font-weight: bold;
}

.login-title > #words {
  font-size: 1.7vw;
}

.user-fields {
  width: 90%;
  height: 12%;
  font-size: 1.5vw;
  margin-top: .5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-fields > label {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 50%;
}
.user-fields > input {
  font-size: 1.5vw;
  width: 100%;
  height: 50%;
  border: thin solid #172e08;
  border-radius: 0.4vw;
}

input:focus {
  outline: none;
}

.form-button-submit {
  margin-top: .5vw;
  width: 90%;
  height: 9%;
  border: thin solid transparent;
  background-color: #172e08;
  color: #d8ffc0;
  font-size: 1.7vw;
  border-radius: 0.4vw;
}
